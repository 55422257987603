<template>
	<div class="RechargeInfo">
		
		<div class="Top">
			<div class="Left">
				充值详情
			</div>
			<div class="Right">
				<span>
					<el-button type="primary" icon="el-icon-arrow-left" @click="$router.go(-1)">返回</el-button>
				</span>
			</div>
		</div>
		
		<div class="List">
			
			<div class="One">
				<li v-if="RechargeInfo != null && RechargeInfo.Id != 'add'">
					<span>充值ID</span>
					<em>
						{{RechargeInfo.Id}}
					</em>
				</li>
				
				<li>
					<span>充值类型</span>
					<em  v-if="RechargeInfo != null">
						{{RechargeInfo.TypeName}}
					</em>
				</li>
				
				<li v-if="RechargeInfo  != null">
					<span>充值发起源</span>
					<em>
						{{RechargeInfo.Source}}
					</em>
				</li>
				
				<li>
					<span>充值渠道</span>
					<em>
						{{RechargeInfo.PayChannelName}}
					</em>
				</li>
				
				<li>
					<span>第三方交易号</span>
					<em>
						{{RechargeInfo.TransactionId}}
					</em>
				</li>
				
				<li>
					<span>充值资金</span>
					<em>
						￥{{RechargeInfo.Amount}}
					</em>
				</li>
				
				<li>
					<span>充值者Hid</span>
					<em>
						{{RechargeInfo.UserHid}}
					</em>
				</li>
				
				<li>
					<span>业务信息</span>
					<em>
						{{RechargeInfo.BaseInfo}}
					</em>
				</li>
				
				<li>
					<span>充值备注</span>
					<em>
						{{RechargeInfo.Remark}}
					</em>
				</li>
				
				<li>
					<span>入账ID</span>
					<em>
						{{RechargeInfo.AccepterId}}
					</em>
				</li>
				
				
				<li>
					<span>充值回调数据</span>
					<em>
						{{RechargeInfo.RechargeParams}}
					</em>
				</li>
				
				<li>
					<span>审核时间</span>
					<em>
						{{RechargeInfo.AuditTime}}
					</em>
				</li>
				
				<li>
					<span>审核说明</span>
					<em>
						{{RechargeInfo.AuditRemark}}
					</em>
				</li>
				
				
				<li v-if="RechargeInfo != null && RechargeInfo.Id != 'add'">
					<span>创建时间</span>
					<em>
						{{RechargeInfo.CreatedAt}}
					</em>
				</li>
				
				<li v-if="RechargeInfo != null && RechargeInfo.Id != 'add'">
					<span>最后修改时间</span>
					<em>
						{{RechargeInfo.UpdatedAt}}
					</em>
				</li>
				
				<li v-if="RechargeInfo != null">
					<span>充值状态</span>
					<em>
						{{RechargeInfo.StatusName}}
					</em>
				</li>
				
			</div>
		</div>
		


	</div>
</template>

<script>
	import {Select,Option} from 'element-ui'
	export default {
	  name: 'RechargeInfo',
	  props: {
	  },
	  data() {
	      return {
			  RechargeInfo:{
				  Id:'',
				  RechargeInfoAccountType:'',
				  RechargeInfoAccountTypeName:'',
				  RechargeInfoBankName:'',
				  RechargeInfoHolder:'',
				  RechargeInfoNo:'',
				  Status:50,
				  StatusName:'',
				  AuditTime:'',
				  AuditorHid:'',
				  AuditRemark:'',
				  AuditProof:'',
				  RechargeInfoAmount:'',
				  RechargeInfoCommissio:'',
				  RechargeInfoTotal:'',
				  CreatedAt:'',
				  UpdatedAt:'',
			  },
			  RefuseDialog:false,
			  AuditRemark:'',
			  AuditProof:'',//审核凭证图片
			  CheckDialog:false,//提现确认框
			  RechargeInfoPayAccountList:[],//提现付款账号列表
			  PayAccountId:'',
	      }
	  },
	  components: {
		'el-select':Select,
		'el-option':Option,
	  },
	  created() {
	  	if(this.$route.params.Id == undefined){
			this.$message('缺少编号ID，页面无法工作')
			return
		}
		this.GetRechargeInfo(this.$route.params.Id)
	  },
	  methods:{
		    GetRechargeInfo(_id){
		  		let data = {Service:'Finance',Class: 'FinanceRecharge',Action: 'Get',Id:_id,}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){
		  				this.$message(res.Msg)
		  				return
		  			}
		  			this.RechargeInfo = res.Data
		  		})
		    },
	},
}
</script>

<style scoped>
.RechargeInfo{
	background-color: #FFFFFF;
	margin: 20px;
	padding: 20px;
}
.RechargeInfo .Top{
	display: flex;
	align-items: center;
}
.RechargeInfo .Top .Left{
	
}
.RechargeInfo .Top .Right{
	flex: 1;
	text-align: right;
	color: #999999;
}

.RechargeInfo .List{
	margin-top: 20px;	
}
.RechargeInfo .List .One{
	border: 1px solid rgba(0,0,0,0.04);
	padding: 20px;
	margin-bottom: 20px;
}
.RechargeInfo .List .One li{
	display: flex;
	line-height: 20px;
	padding: 10px 0px;
	align-items: center;
}
.RechargeInfo .List .One li span{
	width: 140px;
	color: rgba(0,0,0,0.3);
}
.RechargeInfo .List .One li  em{
	flex: 1;
}
.RechargeInfo .List .One li i{
	font-style: normal;
	color: rgba(0,0,0,0.2);
}
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 64px;
    height: 64;
    line-height: 64px;
    text-align: center;
  }
  .avatar {
    width: 64px;
    height: 64px;
    display: block;
  }
</style>
